<template>
<dash-page-new
    :title="$t('Courses')"
>

  <template  #default>
    <v-sheet
        v-if="unverifiedAccounts.length > 0 "
        :color="wsATTENTION"
        class="d-flex justify-space-between align-center pa-6 wsRoundedHalf mt-6"
        dark
    >
      <div class="d-flex align-center">
        <v-icon large class="mr-2">mdi-information</v-icon>
        <h4>{{ $t("UnverifiedWarning") }}</h4>
      </div>


      <ws-button @click="openConfirmAccounts" color="white" class="ml-3" label="Підтвердити" outlined></ws-button>
    </v-sheet>
    <div class="pb-6">
      <v-sheet v-for="(item,i) in items" :key="i" :color="wsLIGHTCARD" class=" align-center wsRoundedHalf mt-5"
               :class="[{'d-flex' : !SM}]"
      >

        <v-sheet
            v-if="!item.img" class="wsRoundedHalfLeft d-flex align-center justify-center"
            :color="wsBACKGROUND"
            :class="[{wsRoundedHalfLeft : !SM} , { wsRoundedHalf : SM }]"
            :width="!SM ? 300 : '100%'"
            :min-width="!SM ? 300 : '100%'"
            height="250"
        >
          <ws-logo height="64"  />
        </v-sheet>

        <v-img v-else
               :src="item.img"
               :width="!SM ? 300 : '100%'"
               :max-width="!SM ? 300 : '100%'"
               height="250"
               :class="[{wsRoundedHalfLeft : !SM} , { wsRoundedHalf : SM }]"
        />

        <div class="pa-4">
          <h3 class="wsDARKER"> {{ item.name }}</h3>
          <h5 class="wsACCENT font-weight-regular my-3">  {{ item.short_description }}</h5>

          <h5 class="wsDARKLIGHT"> {{ item.business.name }}</h5>

          <ws-button @click="gotoCourse(item)" :block="SM" class="mt-5" :color="wsATTENTION" label="ContinueCourse" />
        </div>

      </v-sheet>
    </div>

  </template>


  <template #dialog>
    <ws-dialog
        v-if="displayConfirmAccounts"
        v-model="displayConfirmAccounts"
        :width="!$store.state.auth.user.is_verified ? '400' : '700'"
        :title="!$store.state.auth.user.is_verified ? '' : $t('UniteAccounts')"
        :no-navigation="!$store.state.auth.user.is_verified"
        :save-text="$t('Unite')"
        @save="uniteAccounts"
        :disable-save="loading"
        :loading="loading"
    >

      <div v-if="!$store.state.auth.user.is_verified">
        <verify-window
            class="mt-6"
            @success="verificationSuccess"
            :user="$store.state.auth.user"
        />
      </div>

      <div v-else>

        <h4 class="font-weight-regular">
          {{ $t('UnverifiedDescription')  }}
         </h4>

        <ws-data-table
            :items="selectedUnverifiedAccounts"
            :headers="unverifiedAccountsHeaders"
            class="mt-5"
            no-footer
            disable-pagination
            no-header
            style="border-radius: 4px"
            :style="`border: 1px solid ${wsBACKGROUND}`"
        >
          <template #item.name="{item}">
            <h5 class="wsDARKER text-no-wrap">{{ item.name }}</h5>
            <h5 class="wsDARKLIGHT font-weight-regular text-no-wrap"> {{ PARSE_TIME_STAMP(item.last_activity ) }}</h5>
          </template>

          <template #item.business="{item}">
            <h5 class="wsDARKER">{{ item.business.name }}</h5>
            <h5 class="wsDARKLIGHT d-flex align-center font-weight-regular">
              <v-icon :color="wsDARKLIGHT" small>mdi-link</v-icon>
              westudy.ua/{{ item.business.alias }}
            </h5>
          </template>

          <template #item.courses_count="{item}">
            <div>
              <h5 class="text-no-wrap" v-if="item.courses_count">{{ $t('Courses') }}: {{ item.courses_count }}</h5>
            </div>

          </template>

          <template #item.action="{item}">

            <v-btn :color="wsWARNING" icon @click="selectAccount(item , false)"  >
              <v-icon>mdi-close</v-icon>
            </v-btn>

          </template>

        </ws-data-table>

        <div v-if="unselectedUnverifiedAccounts.length > 0 ">
          <h4 class="font-weight-regular mt-5">Ви відмітили, що наступні облікові записи не мають до вас відношення:</h4>

          <ws-data-table
              :items="unselectedUnverifiedAccounts"
              :headers="unverifiedAccountsHeaders"
              class="mt-5"
              no-footer
              disable-pagination
              no-header
              style="border-radius: 4px"
              :style="`border: 1px solid ${wsBACKGROUND}`"
          >
            <template #item.name="{item}">
              <h5 class="wsDARKER text-no-wrap">{{ item.name }}</h5>
              <h5 class="wsDARKLIGHT font-weight-regular text-no-wrap"> {{ PARSE_TIME_STAMP(item.last_activity ) }}</h5>
            </template>

            <template #item.business="{item}">
              <h5 class="wsDARKER">{{ item.business.name }}</h5>
              <h5 class="wsDARKLIGHT d-flex align-center font-weight-regular">
                <v-icon :color="wsDARKLIGHT" small>mdi-link</v-icon>
                westudy.ua/{{ item.business.alias }}
              </h5>
            </template>

            <template #item.courses_count="{item}">
              <div>
                <h5 class="text-no-wrap" v-if="item.courses_count">{{ $t('Courses') }}: {{ item.courses_count }}</h5>
              </div>

            </template>

            <template #item.action="{item}">

              <v-btn :color="wsDARKLIGHT" icon @click="selectAccount(item , true)"  >
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </template>

          </ws-data-table>

        </div>



      </div>


    </ws-dialog>
  </template>

</dash-page-new>
</template>

<script>
import {mapActions} from "vuex";
import verifyWindow from "@/components/auth/UI/verifyWindow";

export default {
  name: "DashMainCourses",
  components : {
    verifyWindow
  },
  data() {
    return {
      items : [],
      unverifiedAccounts : [],
      displayConfirmAccounts : false,
      loading : false
    }
  },
  computed : {
    selectedUnverifiedAccounts() {
      return this.unverifiedAccounts.filter(el => el.selected)
    },
    unselectedUnverifiedAccounts() {
      return this.unverifiedAccounts.filter(el => !el.selected)
    },
    unverifiedAccountsHeaders() {
      return [
        { text : this.$t('HumanName') , sortable : false ,  value : 'name'         , width : 10 },
        { text : this.$t('School')    , sortable : false,   value : 'business'     , width : '' },
        { text : this.$t('Courses')   , sortable : false,   value : 'courses_count', width : 10 },
        { value : 'action' , sortable : false , width : 10}
      ]
    }
  },
  methods : {
    ...mapActions('unitedUserActions' , ['GET_USER_SCHOOLS_COURSES']),
    ...mapActions('auth' , ['PROCEED_AS', 'UNITE_ACCOUNTS']),

    async uniteAccounts() {
      this.loading = true
      let data = {
        allow : this.selectedUnverifiedAccounts.map(el => el.uuid),
        deny : this.unselectedUnverifiedAccounts.map(el => el.uuid)
      }
      let result = await this.UNITE_ACCOUNTS(data)
      if ( !result ) {
        this.loading = false
        return this.ERROR()
      }
      await this.initPage()
      this.loading = false
      this.displayConfirmAccounts = false
    },

    verificationSuccess() {
      this.$store.state.auth.user.is_verified = true
      this.initPage()
    },
    openConfirmAccounts() {
      this.displayConfirmAccounts = true
    },
    selectAccount(item , confirm) {
      let index = this.unverifiedAccounts.findIndex(el => el.uuid === item.uuid)
      if ( index === -1 ) {
        return
      }

      this.unverifiedAccounts[index].selected = confirm
      this.unverifiedAccounts = this.COPY(this.unverifiedAccounts)
    },

    async gotoCourse(item) {

      if ( this.$store.state.auth.user.business_host === item.business.alias) {
        this.$store.state.business.selectedBusiness = item.business
        this.$router.push(this.businessDashLink(`courses/${item.uuid}/student`))
      } else {

        this.$store.state.business.selectedBusiness = item.business

        let data = {
          'refresh' : localStorage.getItem('refresh_token'),
          'business' : this.$store.state.auth.user.business_host,
          'main_dashboard' : true
        }
        let result = await this.PROCEED_AS(data)
        if ( !result ) {
          return this.ERROR()
        }
        this.$router.push(this.businessDashLink(`courses/${item.uuid}/student`))
      }
    },

    async initPage() {

      let result = await this.GET_USER_SCHOOLS_COURSES()

      if ( !result ) {
        return
      }

      this.items = result.courses
      result.unverified_accounts.forEach(item => {
        item.selected = true
      })

      this.unverifiedAccounts = this.COPY(result.unverified_accounts)

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>